import React from 'react';
import Footer from './Footer';




function Cookies() {
    return (
        <div>           
                <div className="Basic-Text-Frame">
                        <h1>Cookie and privacy policy</h1><br/>
                        <p className="normal">
                        This website captures cookies for statistical purposes. Just like with every website, your IP address could be recorded, but will not be stored permanently.
                        <br /><br /><br />
                        </p>
                       
                        
                </div> 
                <Footer />
                </div>
    );
}

export default Cookies;





