import logo from './logo.svg';
import './App.css';
import React from "react";
import { 
  BrowserRouter as Router, 
  Routes, 
  Link, 
  Route 
} from 'react-router-dom';
import Home from './pages/Home';
import Cookies from './pages/Cookies';


function App() {
  return (
    <Router>
    <div className="App">
      <header className="App-header">
        <Link to="/">
        <img src={logo} className="App-logo" alt="logo" />
        </Link>
      </header>      
  <Routes>
    <Route path='/' element={<Home />} />
    <Route path='/cookies.html' element={<Cookies />} />
   </Routes>   
    </div>
    </Router>
  );
}

export default App;
