import React from 'react';
import Footer from './Footer';



function Home() {
    return (
        <div>
          <h1>Crazy New Ideas</h1><br />
        Email: 
        <a
          className="App-link"
          href="mailto:christian@crazynewideas.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          christian@crazynewideas.com
        </a>
        <Footer />
        </div>
    );
}

export default Home;


