import React from 'react';
import {Link} from 'react-router-dom';

const getDate = () => {
    let d = new Date();
    return d.getUTCFullYear();
}

function Footer() {
    return (
        <div className="App-footer">
        &copy; {getDate()} Crazy New Ideas
        <p>
        <Link to='/'>Home</Link> | 
        <Link to='/cookies.html'>Cookie policy</Link>
            </p>      
        </div>
    );
}


export default Footer;
